import { ICompanySearchResultsItem } from "../../../types/SearchTypes";

export const companies: ICompanySearchResultsItem[] = [
  { ssn: "6204830369", name: "Marel hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6312051780", name: "Icelandair Group hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6702032120", name: "Hagar hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6904090460", name: "Eimskipafélag Íslands hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5702972609", name: "Norðurál Grundartangi ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5203034210", name: "Alcoa Fjarðaál sf.", deregistered: null, deregisteredDate: null },
  { ssn: "6804660179", name: "Rio Tinto á Íslandi hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5402062010", name: "Festi hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4910080160", name: "Íslandsbanki hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4710080280", name: "Landsbankinn hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5810080150", name: "Arion banki hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5602710189", name: "Össur hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4202691299", name: "Landsvirkjun", deregistered: null, deregisteredDate: null },
  { ssn: "5411850389", name: "Brim hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6110881329", name: "Iceland Seafood Internat. hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6102973079", name: "Samherji hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5002693249", name: "Olís ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5512983029", name: "Orkuveita Reykjavíkur", deregistered: null, deregisteredDate: null },
  { ssn: "6407060310", name: "Actavis Group PTC ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4110033370", name: "N1 ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "7112982239", name: "Krónan ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6309212010", name: "Skeljungur ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6801695009", name: "Kaupfélag Skagfirðinga", deregistered: null, deregisteredDate: null },
  { ssn: "6406750209", name: "Elkem Ísland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4911051270", name: "Eignarhaldsfélagið Hof ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5702697479", name: "Síldarvinnslan hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6801691019", name: "Kaupfélag Suðurnesja ( svf. )", deregistered: null, deregisteredDate: null },
  { ssn: "4101694369", name: "Áfengis-/tóbaksverslun ríkisins", deregistered: null, deregisteredDate: null },
  { ssn: "5712983769", name: "Samkaup hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4602690599", name: "Auðhumla svf.", deregistered: null, deregisteredDate: null },
  { ssn: "5405022930", name: "Kvika banki hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5502100370", name: "Isavia ohf.", deregistered: null, deregisteredDate: null },
  { ssn: "4411081090", name: "Haru Holding ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6711060670", name: "Össur Iceland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6302110500", name: "BL ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6503871639", name: "Flugfélagið Atlanta ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "7103110640", name: "UK fjárfestingar ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4409861539", name: "Samskip hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6704002390", name: "Norvik hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6803170460", name: "Air BP Iceland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "7012770239", name: "Brimborg ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4601693219", name: "Byko ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6812160300", name: "Guðrúnarborg ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5306023380", name: "Veritas ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "7002693299", name: "Vinnslustöðin hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5901693079", name: "Hampiðjan hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5012131870", name: "Veitur ohf.", deregistered: null, deregisteredDate: null },
  { ssn: "6404062080", name: "Mókollur ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6806044010", name: "Distica hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4203697789", name: "Ölgerðin Egill Skallagríms hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6509091270", name: "Sjóvá-Almennar tryggingar hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5512110290", name: "Húsasmiðjan ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6803190730", name: "Orkan IS ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4711972389", name: "Iceland Seafood ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6906892009", name: "Vátryggingafélag Íslands hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4602070880", name: "Síminn hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5803100600", name: "Arnarlax ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6601691219", name: "Ísfélag hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5808042410", name: "Landsnet hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4709051740", name: "Sýn hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4507042290", name: "Bílaumboðið Askja ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "7006140690", name: "Costco Wholesale Iceland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4109982629", name: "Útgerðarfélag Reykjavíkur hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4302141520", name: "Ístak hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6701695459", name: "Fagkaup ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5302922079", name: "Origo hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6603190180", name: "Fly Play hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5301710279", name: "Toyota á Íslandi ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5902691749", name: "Skel fjárfestingafélag hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4305071840", name: "Eignarhaldsfélagið Lyng ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6706050780", name: "Controlant hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5804060680", name: "TK bílar ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5804071100", name: "Lánasjóður sveitarfélaga ohf.", deregistered: null, deregisteredDate: null },
  { ssn: "5202692669", name: "Rarik ohf.", deregistered: null, deregisteredDate: null },
  { ssn: "6212002320", name: "Parlogis ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5002090620", name: "Útgerðarfélag Akureyringa ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4201080600", name: "Iceland Pelagic ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6304023870", name: "Vistor ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6001695139", name: "Hekla hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6602693399", name: "TM tryggingar hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4801692989", name: "Skinney-Þinganes hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5610003280", name: "Elko ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4907922369", name: "Bláa Lónið Ísland hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5006830589", name: "Rapyd Europe hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6301694299", name: "Eskja hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4107861179", name: "Nesfiskur ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6801697649", name: "Kaupfélag Fáskrúðsfirðinga svf", deregistered: null, deregisteredDate: null },
  { ssn: "5812012650", name: "Loðnuvinnslan hf. Fáskrúðsfirði", deregistered: null, deregisteredDate: null },
  { ssn: "4402695089", name: "Lýsi hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6202696119", name: "Icepharma hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4612891269", name: "FISK-Seafood ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5212130190", name: "ON Power ohf.", deregistered: null, deregisteredDate: null },
  { ssn: "5105061970", name: "ASI ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4410993399", name: "Vörður tryggingar hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4712992359", name: "Icelandair Cargo ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5811982569", name: "ÞG verktakar ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4603170290", name: "Steinasalir ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5412932809", name: "Miklatorg hf.", deregistered: null, deregisteredDate: null },
  { ssn: "7112080700", name: "Reitir fasteignafélag hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4304032810", name: "H5 ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6301692919", name: "Íslandshótel hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4710081840", name: "MarF ehf", deregistered: null, deregisteredDate: null },
  { ssn: "6511740239", name: "Höldur ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5609210470", name: "Eyjaberg ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6112002770", name: "Ó. Johnson & Kaaber - ÍSAM ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6503871399", name: "Innnes ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6607070420", name: "Steypustöðin ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6912953549", name: "Íslensk erfðagreining ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4203690429", name: "Þorbjörn hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6812711559", name: "Rammi hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5312050810", name: "Nova hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6209160560", name: "Nova klúbburinn hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6112042130", name: "Fríhöfnin ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4701691419", name: "Coca-Cola Eur.Partn. Ísland ehf", deregistered: null, deregisteredDate: null },
  { ssn: "7011810779", name: "Vísir ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6301091080", name: "Reginn hf.", deregistered: null, deregisteredDate: null },
  { ssn: "7101130410", name: "Alvotech hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6205032660", name: "VSV Seafood Iceland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5902070120", name: "TDK Foil Iceland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "7110140980", name: "Smyril Line Ísland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5310160250", name: "Eignarhaldsfélagið Kolka ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4711190830", name: "Orka náttúrunnar ohf.", deregistered: null, deregisteredDate: null },
  { ssn: "6110042570", name: "Arcus ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6502992649", name: "Lyf og heilsa hf.", deregistered: null, deregisteredDate: null },
  { ssn: "6202191260", name: "Tesla Motors Iceland ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "6510070620", name: "KERECIS hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5812891899", name: "Kjarnafæði Norðlenska hf.", deregistered: null, deregisteredDate: null },
  { ssn: "4507110980", name: "Eignarhaldsfélagið Hornsteinn e", deregistered: null, deregisteredDate: null },
  { ssn: "6804750169", name: "HS Orka hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5912130160", name: "Orkuveita Reykjavíkur-vatns sf.", deregistered: null, deregisteredDate: null },
  { ssn: "4201151050", name: "Bacco Seaproducts ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4404191940", name: "Truenorth Nordic ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5303901289", name: "Bananar ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "4903042580", name: "Northern Lights Leasing ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5909023730", name: "Eik fasteignafélag hf.", deregistered: null, deregisteredDate: null },
  { ssn: "5906023610", name: "Atlantsolía ehf", deregistered: null, deregisteredDate: null },
  { ssn: "6212976949", name: "BERJAYA HOTELS ICELAND hf", deregistered: null, deregisteredDate: null },
  { ssn: "4506973469", name: "CCP ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5005013160", name: "Strætó bs.", deregistered: null, deregisteredDate: null },
  { ssn: "4311200110", name: "StorMar ehf.", deregistered: null, deregisteredDate: null },
  { ssn: "5308022640", name: "Danól ehf.", deregistered: null, deregisteredDate: null },
];
