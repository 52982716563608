// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Table from '../../ui-elements/Table/Table';
import Button from '../../ui-elements/Button/Button';
import Loading from '../../ui-elements/Loading/Loading';
import Label from '../../ui-elements/Label/Label';
import { MAX_RESULTS, ResultStatus, getItemHref } from './SearchPage';
// Service imports:
import { GET_KELDAN_API_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
import { insertDash } from '../../services/utils';
// Type imports:
import { IApiLmdAutocompleteItem, ICompanySearchResults } from '../../types/SearchTypes';
import { Fetched } from '../../types/Types';

const CompanySearch: React.FC<{
    term: string | null,
    onResultsChange: (hasResults: ResultStatus) => void;
}> = ({term, onResultsChange}) => {

    const [companyList, setCompanyList] = useState<Fetched<IApiLmdAutocompleteItem[]>>(null);
    const [isItemLoading, setIsItemLoading] = useState(false);

    useEffect(() => {
        const companySearch = async () => {
            if (term === null) return;
            if (term.length < 1) return;
            try {
                setIsItemLoading(true)
                const url = `${GET_KELDAN_API_URL()}/Company/SearchRskConnector?searchTerm=${encodeURIComponent(term)}`;
                const response = await fetch(url);
                if (response.ok) {
                    const body: ICompanySearchResults = await response.json();
                    const companies: IApiLmdAutocompleteItem[] = body.results?.map(({ssn, name, deregistered}) => ({
                        Symbol: ssn,
                        Name: name,
                        Tickertype: 'company',
                        Deregistered: deregistered !== null && deregistered !== ""
                    })) ?? [];
                    setIsItemLoading(false)
                    if(companies.length === 0) {
                        onResultsChange('no results')
                        setCompanyList([])
                    } else {
                        setCompanyList([...companies].sort((a, b) => {
                            if (a.Name === null && b.Name === null) return 0;
                            if (a.Name === null) return -1;
                            if (b.Name === null) return 1;
                            if (a.Name < b.Name) return -1
                            if (a.Name > b.Name) return 1;
                            else return 0;
                        }));
                        onResultsChange('has results')
                    }
                } else {
                    setCompanyList(new Error(ErrorMessages.RequestFailed));
                    setIsItemLoading(false)
                    onResultsChange('no results')
                }
            } catch (e) {
                setCompanyList(new Error(ErrorMessages.NetworkError));
                setIsItemLoading(false)
                onResultsChange('no results')
            }
        }
        companySearch();
    }, [term]);
    if (term === null) return null;
    if (term.length < 1) return null;
    if(companyList instanceof Error) return null
    if(companyList === null || companyList.length === 0) return null
    return (
        <div className='table-section company-search'>
            <h4>Fyrirtæki</h4>
            {isItemLoading ? <Loading /> : <Table 
                data={companyList.slice(0,MAX_RESULTS)}
                columns={[{
                    title: 'Nafn',
                    renderCell: ( datum ) => ( 
                        <a className='clickableRow' href={getItemHref(datum)}>
                            <span className='name'>{datum.Name}</span>
                            {datum.Deregistered && <Label size='md' labelType='closed' text='Afskráð' />}
                        </a>
                    ),
                    textAlign: 'left',
                }, {
                    title: 'Kennitala',
                    renderCell: ( datum ) => ( 
                        <a className='clickableRow' href={getItemHref(datum)}>
                            <span>{insertDash(datum.Symbol)}</span>
                        </a>
                    ),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ( datum ) => ( 
                        <a className='anchorWrapForButton' href={getItemHref(datum)}>
                            <span>
                                <Button buttonType='secondary' size='sm'>Skoða</Button>
                            </span>
                        </a>
                    )
                }]}
                tableSize='lg'
            />}
        </div>
    );
} 
export default CompanySearch;