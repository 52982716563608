// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { format } from 'date-fns';
import isIsLocale from 'date-fns/locale/is';
import enGBLocale from 'date-fns/locale/en-GB';
import DOMPurify from 'dompurify';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import LanguagePicker from '../../ui-elements/LanguagePicker/LanguagePicker';
import Label from '../../ui-elements/Label/Label';
import NewsOrgInfo from '../NewsOrgInfo/NewsOrgInfo';
import Link from '../../ui-elements/Link/Link';
import SocialMediaSharing from '../SocialMediaSharing/SocialMediaSharing';
// Service imports:
import { ErrorMessages } from '../../services/errorMessages';
import { GET_NEWS_LMD_URL } from '../../services/config';
// Type imports:
import { Fetched } from '../../types/Types';
import { INewsLmdResponse } from '../../types/NewsTypes';

const ViewNewsArticle: React.FC<{id: string}> = ({
    id
}) => {
    const [data, setData ] = useState<Fetched<INewsLmdResponse>>();
    const [newsId, setNewsId] = useState(id);
    const [lang, setLang] = useState('IS');

    const fetchData = async (idToFetch: string) => {
        try {
            const url = `${GET_NEWS_LMD_URL()}/search/get/${idToFetch}`
            
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: Fetched<INewsLmdResponse> = await response.json();
                setData(responseBody)

            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }
    useEffect(() => {
        const idFormat = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{13}/;
        const match = newsId.match(idFormat);
        if (match) {
            fetchData(match[0])
        } else {
            fetchData(newsId)
        }
    }, [newsId])

    const feedItem = useMemo(() => {
        if (!data || data instanceof Error || data.feedItems === null || data.feedItems.length === 0) {
            return null
        }
        return data?.feedItems[0]
    }, [data])

    const cleanHtmlTitle = useMemo(() => {
        return DOMPurify.sanitize(feedItem?.title ?? '')
    }, [feedItem])

    const cleanHtml = useMemo(() => {
        return DOMPurify.sanitize(feedItem?.bodyHtml ?? '')
    }, [feedItem])
    
    const displayBody = () => {
        if (data instanceof Error) {
            return (
                <Alert type={'error'} headText={data.message} />
            )
        }
        if (feedItem === null) {
            return (
                (data && data.feedItems && data.feedItems.length === 0)
                    ? <Alert type='alert' headText='Ekki fundust gögn fyrir þessa frétt' />
                    : <Loading />
            )
        }
        return (<>
            <HeadSection
                breadcrumbs={
                    <Link href="/Kauphallartilkynningar" icon='back' linkSize='14'>
                        Allar kauphallartilkynningar
                    </Link>
                }
                hasAds='main'
            />
            <div className='grid-items'>
                <div className='grid-item grid-item--3of4 grid-item--table-full'>
                    <DisplayBox
                        titleComponent={<span dangerouslySetInnerHTML={{__html: cleanHtmlTitle}} />}
                        asideComponent={
                            feedItem.writtenInLang.length > 1
                                ? <LanguagePicker
                                    availableLanguages={
                                        feedItem.writtenInLang.map(l => ({ lang: l.lang, value: l.linkId }))
                                    }
                                    defaultValue={decodeURI(newsId)}
                                    onChange={(val) => {
                                        setNewsId(val ?? '')
                                    }}
                                    setLanguage={setLang}
                                />
                                : undefined
                        }
                        displayBoxSize='xxl'
                        footerLeft={
                            <div className='origin-wrapper'>
                                <span className='origin-label'>
                                    {lang === 'IS' ? 'HEIMILD' : 'SOURCE'}
                                </span>
                                <span className='extra-info'>
                                    {feedItem.distributor?.toLowerCase() === 'globenewswire' || feedItem.distributor?.toLowerCase() === 'mfn'
                                        ? <a href={feedItem.link ?? ''} target='_blank' rel='noreferrer'>
                                            {feedItem.distributor}
                                        </a>
                                        : feedItem.feedMeta?.sourceName
                                    }
                                </span>
                            </div>
                        }
                        footerRight={
                            <SocialMediaSharing dataTitle={feedItem.title ?? ''} />
                        }
                    >
                        <div className='time-and-category'>
                            <span>
                                {format(
                                    new Date(feedItem.publish_timestamp ?? 0),
                                    'PPPpp',
                                    { locale: lang === 'IS' ? isIsLocale : enGBLocale }
                                )}
                            </span>
                            <span className='source'>{feedItem.feedMeta?.sourceName}</span>
                            {feedItem.feedMeta?.feedType === 'press_release' && (
                                lang === 'IS'
                                    ? <Label
                                        labelType='news-release'
                                        text='Fréttatilkynning'
                                        size='lg'
                                    />
                                    : <Label
                                        labelType='news-release'
                                        text='Press release'
                                        size='lg'
                                    />
                            )}
                            {feedItem.feedMeta?.feedType === 'exchange_notice' && (
                                lang === 'IS'
                                    ? <Label
                                        labelType='news-release'
                                        text='Kauphallartilkynning'
                                        size='lg'
                                    />
                                    : <Label
                                        labelType='news-release'
                                        text='Exchange notice'
                                        size='lg'
                                    />
                            )}
                            <button onClick={() => window.print()} className='print-button' title='Prenta'>
                                <FontAwesomeIcon icon={faPrint} />
                            </button>
                        </div>
                        <div className='news-body'>
                            <div className="news-entry paragraph" dangerouslySetInnerHTML={{__html: cleanHtml}} />
                        </div>
                    </DisplayBox>
                </div>
                <div className='grid-item grid-item--1of4 grid-item--table-full'>
                    <NewsOrgInfo
                        symbol={feedItem.feedMeta?.sourceKey ?? ''}
                        feedKey={feedItem.feedMeta?.feedKey ?? ''}
                        sourceKey={feedItem.feedMeta?.sourceKey ?? ''}
                    />
                </div>
            </div>
        </>)
    }
    
    return <div className='main KCL_view-news-article'>
        <div className="main__inner-fluid">
            <div className="shell">
                {displayBody()}
            </div>
        </div>
    </div>
}

export default ViewNewsArticle;
