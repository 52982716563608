// Package imports:
import React from 'react';

export type MetalLogoNames = 'XAU' | 'XPD' | 'XPT' | 'XAG';

interface IProps {
    name: MetalLogoNames,
};

const MetalLogo: React.FC<IProps> = ({name}) => {
    return (
        <div className="KCL_metal-logo">
            <img
                src={`https://cdn.livemarketdata.com/Avatars/Metals/${name}.svg`}
                alt={name}
                width="25"
                height="25"
            />
        </div>
    );
}

export default MetalLogo;