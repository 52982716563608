// Package imports:
import React, { useEffect, useRef, useState } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
// Service imports:
import { getTableDate } from '../../services/utils';
import { useFetchedApiLmdData } from '../../services/apiHooks';
import { GET_NEWS_LMD_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
import { useRefreshRateFetching } from '../../services/hooks';
// Type imports:
import { IApiLmdSnapshot } from '../../types/HlutabrefTypes';
import { Fetched, IDefaultProps } from '../../types/Types';
import { INewsLmdNewsItem, INewsLmdResponse } from '../../types/NewsTypes';

interface IOwnProps {
    market?: string,
    limit?: number
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const NewsByMarkets: React.FC<Props> = ({
    market = 'ISEQ SHR',
    limit = 25,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const symbols = useFetchedApiLmdData<string[]>(
        '/v1/market_data/v1/keldan/exchanges/[XICE;FNIS]/equities/delayed_snapshot',
        accessToken,
        (data) => {
            if (data instanceof Error || !data) return [];
            return data.map((item: IApiLmdSnapshot) => item.symbol)
        }
    );
    
    const [data, setData] = useState<Fetched<INewsLmdNewsItem[]>>(null);

    // Use a ref to store the latest value of symbols
    const symbolsRef = useRef<Fetched<string[]>>(symbols);

    const fetchData = async () => {
        if (symbolsRef.current instanceof Error || symbolsRef.current === null || symbolsRef.current.length === 0) {
            setData(new Error(ErrorMessages.NoDataFound));
            return;
        }
        try {
            const url = `${GET_NEWS_LMD_URL()}/search/query?f=symbol.ticker&limit=${limit}&q=("${symbolsRef.current.join('" "')}")&start=0&filter=feedMeta.feedType=exchange_notice`;
            const response = await fetch(url);
            if (response.ok) {
                const body: INewsLmdResponse = await response.json();
                setData(body.feedItems);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    };

    // Update the ref whenever symbols changes and trigger fetch
    useEffect(() => {
        symbolsRef.current = symbols;
        fetchData();
    }, [symbols]);

    useRefreshRateFetching(
        fetchData,
        refreshRateMs,
        accessToken
    );

    const getLink = (item: INewsLmdNewsItem) => {
        const { link, id, distributor } = item;
        if (distributor?.toLowerCase() === 'globenewswire' || distributor?.toLowerCase() === 'mfn' || distributor?.toLowerCase().includes('keldan'))
            return { link: `/Tilkynningar/${id}`, target: '_self' };
        return { link: link ?? '#', target: '_blank' };
    }
    

    const displayNews = () => {
        if (data === null) return <Loading />
        if (data instanceof Error) return <Alert type="error" headText={data.message} />
        if (data.length === 0) return <p>Engar fréttir fundust</p>
        return data.map((d) => {
            const { link, target } = getLink(d);
            return <div className="news-row" key={d.id}>
                <span>
                    <Link
                        className="news-title"
                        url={link}
                        target={target}
                        linkSize='15'
                        splitLines
                    >
                        {d.title}
                    </Link>
                </span>
                <div className="name-and-time">
                    <span className='source'>{d.fullSource}</span>
                    <span className="time">{getTableDate(d.publish_date, 'HH:MM', '.')}</span>
                </div>
            </div>
        });
    }

    return (
        <DisplayBox
            className='KCL_news-by-markets'
            title="Fréttir úr Kauphöllinni"
            {...props}
        >
            {displayNews()}
        </DisplayBox>
    );
}

export default NewsByMarkets;