// Package imports:
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFile } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../ui-elements/Table/Table';
import Link from '../../ui-elements/Link/Link';
import SmallSearch from '../SmallSearch/SmallSearch';
import Loading from '../../ui-elements/Loading/Loading';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import Button from '../../ui-elements/Button/Button';
import NewsOrgInfo from '../NewsOrgInfo/NewsOrgInfo';
// Service imports:
import { getTableDate, getTimeFromDate } from '../../services/utils';
import { GET_NEWS_LMD_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
import { useStateRef } from '../../services/hooks';
// Type imports:
import { INewsLmdNewsItem, INewsLmdResponse, NewsItemFeedIdMapToBoolean, SearchInputToResultsMap, SearchResultsInfo } from '../../types/NewsTypes';
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

/**
 * Here is what these words mean:
 * - incomplete: There are less items being displayed than should be. Must fetch more.
 * - complete: There are enough items being displayed, but more can be loaded if the user wants.
 * - fully loaded: We are displaying literally all news for the given search query.
 * - no data: It is still loading or there is an error. No data is present.
 */
type DataStatus = 'incomplete' | 'complete' | 'fully loaded' | 'no data' ;

interface IDataMemo {
    dataToDisplay: INewsLmdNewsItem[] | null,
    dataStatus: DataStatus
};

const ITEMS_TO_DISPLAY_PER_FETCH = 50;
const INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH = 40;
const NEWS_ITEMS_FETCH_ON_INDEX_CHANGE = 100;

const MINIMUM_LETTER_SEARCH = 3;

interface IProps {
    id: string
}

const AllExchangeNoticesPage: React.FC<IProps> = ({
    id
}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const sourceKey = urlParams.get('sourceKey');
    const accessToken =  useContext(AccessTokenContext);
    const [defaultProps, setDefaultProps] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: 60 * 1000
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);
    // State variables:
    // News items visible to the user.
    const [ defaultNewsItems, setDefaultNewsItems ] = useState<INewsLmdNewsItem[] | null>(null);
    // How many news items should be displayed. newsItemsData will fetch more than the user wants to use the effciency of the request.
    const [ itemsPerPage, setItemsPerPage ] = useState(ITEMS_TO_DISPLAY_PER_FETCH);
    // Any error if it would occur
    const [newsError, setNewsError] = useState<Error | null>(null);
    // Search word
    const [searchInput, setSearchInput] = useState('');
    // Search word results map
    const [searchResultsMap, setSearchResultsMap] = useState<SearchInputToResultsMap>({});
    // While loading new data, show the last displayable data. Makes searching more UX friendly
    const [lastDisplayableData, setLastDisplayableData] = useState<INewsLmdNewsItem[] | null>(null);
    
    const [newsOrgName, setNewsOrgName] = useState('');
    const [defaultTotalCount, setDefaultTotalCount] = useState(0);

    const usingSearchWord = useMemo(() => {
        return (searchInput.length >= MINIMUM_LETTER_SEARCH);
    }, [ searchInput ]);

    const { dataToDisplay, dataStatus } = useMemo(() => {
        const noDataState: IDataMemo = {
            dataToDisplay: [],
            dataStatus: 'no data'
        }

        if (!usingSearchWord) {
            if (defaultNewsItems === null) return noDataState;
            const dataSlice = defaultNewsItems.slice(0, itemsPerPage);
            let dataStatus: DataStatus;
            if (dataSlice.length === defaultTotalCount) {
                dataStatus = 'fully loaded';
            } else if (dataSlice.length === itemsPerPage) {
                dataStatus = 'complete';
            } else {
                dataStatus = 'incomplete';
            }
            const dataMemo: IDataMemo = {
                dataToDisplay: dataSlice,
                dataStatus: dataStatus
            }
            return dataMemo;
        }

        const searchResults = searchResultsMap?.[searchInput];

        if (searchResults === undefined || searchResults.results instanceof Error) return noDataState;

        const dataSlice = searchResults.results.slice(0, itemsPerPage);
        let dataStatus: DataStatus;
        if (dataSlice.length === searchResults.lastResultsLength || dataSlice.length === searchResults.totalCount) {
            dataStatus = 'fully loaded';
        } else if (dataSlice.length === itemsPerPage) {
            dataStatus = 'complete';
        } else {
            dataStatus = 'incomplete';
        }

        const dataMemo: IDataMemo = {
            dataToDisplay: dataSlice,
            dataStatus
        };
        return dataMemo;
    }, [ searchInput, searchResultsMap, defaultNewsItems, itemsPerPage, usingSearchWord ]);

    // Maps to track if news are new or read.
    const [ newsItemFeedIdToIsNewMap, setNewsItemFeedIdToIsNewMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    const [ newsItemFeedIdToIsHighlightedMap, setNewsItemFeedIdToIsHighlightedMap ] = useState<NewsItemFeedIdMapToBoolean>({});

    // Interval functions can't read state correctly. Needs ref's instead.
    const defaultNewsItemsRef = useStateRef(defaultNewsItems)
    const newsItemFeedIdToIsNewMapRef = useStateRef(newsItemFeedIdToIsNewMap);
    const newsItemFeedIdToIsHighlightedMapRef = useStateRef(newsItemFeedIdToIsHighlightedMap);
    const usingSearchWordRef = useStateRef(usingSearchWord);
    const searchResultsMapRef = useStateRef(searchResultsMap);

    // Ref for timeout id.
    const timeoutFunctionIdRef = useRef<number | null>(null);

    // HELPER FUNCTIONS:
    const combineNewAndOldNews = (newNews: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[]) => {
        const oldNewsIdMap = oldNews.reduce((obj: NewsItemFeedIdMapToBoolean, newsItem) => {
            const { id } = newsItem;
            if (!id) return obj;
            return {
                ...obj,
                [id]: true
            };
        }, {});
        const pureNewNews = newNews.filter(newsItem => (newsItem.id !== null && !oldNewsIdMap[newsItem.id]));

        // Append new news items onto visible news.
        const joinedListOfOldAndNewNews = newNews.concat(oldNews);
        // There could be some overlap between the end of visiblenews and the start of newnews items.
        // So we remove duplicates.
        const joinedNewsWithoutDuplicates = [];
        // Map newsitem.id to boolean. Faster timecomplexity than "visibleNewsWithoutDuplicates.includes()";
        const hasNewsItemBeenAdded: {
            [feedId in string]?: boolean
        } = {};

        for (const newsItem of joinedListOfOldAndNewNews) {
            if (newsItem.id === null) continue;
            if (hasNewsItemBeenAdded[newsItem.id]) continue;
            hasNewsItemBeenAdded[newsItem.id] = true;
            joinedNewsWithoutDuplicates.push(newsItem);
        }

        return {
            combinedNews: joinedNewsWithoutDuplicates,
            pureNewNews
        };
    }

    const fetchNews = async (
        numberOfNewsItemsToFetch: number,
        onSuccess: (newsItems: INewsLmdNewsItem[], totalCount: number) => void,
        onFail: (err: Error) => void,
        searchQuery?: string
    ) => {
        // Fetch items
        let baseUrl = `${GET_NEWS_LMD_URL()}/search/query`;
        const urlSearchParams = new URLSearchParams({
            start: '0',
            limit: numberOfNewsItemsToFetch.toString()
        });
        if (searchQuery) {
            urlSearchParams.set('f', 'title;body');
            urlSearchParams.set('q', searchQuery);
            urlSearchParams.set('filter', `feedMeta.feedKey=${id}`);
        } else {
            urlSearchParams.set('f', 'feedMeta.feedKey');
            urlSearchParams.set('q', id);
        }

        const url = `${baseUrl}?${urlSearchParams.toString()}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.ok) return res.json();
            else throw new Error(ErrorMessages.RequestFailed);
        })
        .then((resBody: INewsLmdResponse) => {
            if (resBody === null || resBody.feedItems === null) throw new Error('Server did not return any news.')
            let removeFutureNews = resBody.feedItems.filter(x => x.publish_timestamp === null || x.publish_timestamp <= Date.now());
            onSuccess(removeFutureNews, resBody.totalCount);
        })
        .catch(err => {
            if (err instanceof Error) {
                onFail(err);
            } else {
                onFail(new Error(ErrorMessages.NetworkError));
            }
        });
    }

    // Update lastDisplayableData to last viable data.
    useEffect(() => { if (dataToDisplay !== null) setLastDisplayableData(dataToDisplay) }, [dataToDisplay]);

    // Reset items per page on search input change
    useEffect(() => {
        setItemsPerPage(ITEMS_TO_DISPLAY_PER_FETCH);
    }, [ searchInput ]);

    // Initial data fetch:
    useEffect(() => {
        fetchNews(
            NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
            (newsItems, totalCount) => {
                setDefaultNewsItems(newsItems);
                setDefaultTotalCount(totalCount);
            },
            (err) => {
                setNewsError(err);
            }
        )
    }, []);

    // DefaultNewsItems: "Load more news" based fetch
    useEffect(() => {
        // Can only load more if defaultnewsitems has been fetched, we are not searching, and there isn't enough news yet fetched.
        if (defaultNewsItems !== null && !usingSearchWord && dataStatus === 'incomplete') {
            fetchNews(
                itemsPerPage,
                (olderNews) => {
                    const { combinedNews } = combineNewAndOldNews(defaultNewsItems, olderNews);
                    setDefaultNewsItems(combinedNews);
                },
                (err) => {
                    setNewsError(err);
                }
            );
        }
    }, [ dataStatus, usingSearchWord, defaultNewsItems, itemsPerPage ]);

    // Refresh rate based fetch
    const newNewsRefreshFunction = () => {
        const defaultNewsItems = defaultNewsItemsRef.current;
        const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
        const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
        const usingSearchWord = usingSearchWordRef.current;
        const searchResultsMap = searchResultsMapRef.current;

        const combineOldAndNewNews = (newNewsItems: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[]) => {

            const firstOldNews = oldNews[0];
            let indexWhereOldNewsBegins = newNewsItems.findIndex(newsItem => (
                (
                    newsItem.id !== null
                    && newsItem.id === firstOldNews.id
                )
                || (
                    newsItem.publish_timestamp !== null
                    && firstOldNews.publish_timestamp !== null
                    && newsItem.publish_timestamp < firstOldNews.publish_timestamp
                )
            ));

            const addedNews = indexWhereOldNewsBegins !== -1 ? newNewsItems.slice(0, indexWhereOldNewsBegins) : [];
            const combinedNews = [...addedNews, ...oldNews];
            return {
                combinedNews,
                addedNews
            };
        }

        // Always update default news and non-searchword,yes-sourcefilter news.
        if (defaultNewsItems !== null) {
            const recursionStoppingNewsItem = (defaultNewsItems[0] ?? null) as INewsLmdNewsItem | null;
            if (recursionStoppingNewsItem === null || recursionStoppingNewsItem.id === null) return;
            const fetchNewNews = (numberToFetch: number, recursionTime = 0) => {
                fetchNews(
                    numberToFetch,
                    (newNewsItems, totalCount) => {
                        // Check if it has retreived all new news.
                        // Do this by finding index of the first old news item. (old as in already fetched).
                        let indexWhereOldNewsBegins = newNewsItems.findIndex(newsItem => (
                            (
                                newsItem.id !== null
                                && newsItem.id === recursionStoppingNewsItem.id
                            )
                            || (
                                newsItem.publish_timestamp !== null
                                && recursionStoppingNewsItem.publish_timestamp !== null
                                && newsItem.publish_timestamp < recursionStoppingNewsItem.publish_timestamp
                            )
                        ));
                        // If not try again with higher limit.
                        if (indexWhereOldNewsBegins === -1) {
                            // RECURSION ALERT:
                            // If for some reason something goes wrong, this will break us out of recursion after 3 loops:
                            if (recursionTime < 3) fetchNewNews(numberToFetch*2, recursionTime+1);
                            return;
                        }

                        let newsThatGetNotification: INewsLmdNewsItem[] = [];

                        // Handle default news:
                        const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, defaultNewsItems);
                        
                        // If usin
                        if (!usingSearchWord) newsThatGetNotification = addedNews;
                        setDefaultNewsItems(combinedNews);
                        setDefaultTotalCount(totalCount);

                        // Handle each source filter case:
                        let sourceFilterToResultsMap = searchResultsMap?.[''];
                        let newSourceFilterToResultsMap: SearchResultsInfo = sourceFilterToResultsMap ? { ...sourceFilterToResultsMap } : { results: [], totalCount: 0 };
                        if (sourceFilterToResultsMap !== undefined) {
                            const { results, totalCount } = sourceFilterToResultsMap;
                            if (!(results instanceof Error)) {
                                const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, results);
                                
                                if (!usingSearchWord) {
                                    newsThatGetNotification = addedNews;
                                }
                                newSourceFilterToResultsMap = {
                                    results: combinedNews,
                                    totalCount: totalCount + addedNews.length
                                }
                            }
                        }
                        // Initialize new states here, that will be updated
                        const newSearchResultsMap = {
                            ...searchResultsMap,
                            '': newSourceFilterToResultsMap
                        };
                        
                        // Update isNew map.
                        const newNewsItemsToTrueMap: NewsItemFeedIdMapToBoolean = {};
                        const newNewsItemsToFalseMap: NewsItemFeedIdMapToBoolean = {};
                        newsThatGetNotification.forEach(newsItem => {
                            const { id } = newsItem;
                            if (id) {
                                newNewsItemsToTrueMap[id] = true;
                                newNewsItemsToFalseMap[id] = false;
                            }
                        });
                        
                        const newNewsItemFeedIdToIsNewMap = {
                            ...newsItemFeedIdToIsNewMap,
                            ...newNewsItemsToTrueMap
                        };
                        const newNewsItemFeedIdToIsHighlightedMap = {
                            ...newsItemFeedIdToIsHighlightedMap,
                            ...newNewsItemsToTrueMap
                        };

                        // Finish by setting all new states and time outs
                        setSearchResultsMap(newSearchResultsMap);
                        // Set timeouts for resetting isNew notifier + highlight.
                        setNewsItemFeedIdToIsHighlightedMap(newNewsItemFeedIdToIsHighlightedMap);
                        setNewsItemFeedIdToIsNewMap(newNewsItemFeedIdToIsNewMap);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
                            setNewsItemFeedIdToIsHighlightedMap({
                                ...newsItemFeedIdToIsHighlightedMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*1000 /*15 sek.*/);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
                            setNewsItemFeedIdToIsNewMap({
                                ...newsItemFeedIdToIsNewMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*60*1000 /* 15 min. */);
                    },
                    (err) => {
                        setNewsError(err);
                    }
                )
            }
            fetchNewNews(INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH);
        }
    }

    useEffect(() => {
        if (defaultProps.refreshRateMs) {
            // If refresh rate, set fetchData on an interval
            const intervalId = window.setInterval(() => {
                newNewsRefreshFunction();
            }, defaultProps.refreshRateMs);
            // Clean up: clear interval to avoid memory leaks
            return () => window.clearInterval(intervalId);
        }
    }, [ defaultProps.refreshRateMs ]);

    // Search based fetch.
    useEffect(() => {
        if (!usingSearchWord || dataStatus === 'complete' || dataStatus === 'fully loaded') return;

        // Helper delay function.
        const withDelay = (func: () => void) => {
            const timeoutFunctionId = timeoutFunctionIdRef.current;
            if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);
            timeoutFunctionIdRef.current = window.setTimeout(func, 400);
        }
        
        const searchWord = (usingSearchWord)
            ? searchInput
            : '';

        const searchResults = searchResultsMap?.[searchWord];
        if (searchResults && searchResults.results instanceof Error) return;

        const lastResultsLength = (searchResults && Array.isArray(searchResults.results)) ? searchResults.results.length : 0;
        const desiredAmountOfNews = (searchResults && searchResults.totalCount < itemsPerPage) ? searchResults.totalCount : itemsPerPage;

        const fetchNewsFunction = () => fetchNews(
            desiredAmountOfNews,
            (results, totalCount) => {
                const newSearchResultsMap = {
                    ...searchResultsMap,
                    [searchWord]: {
                        results,
                        totalCount,
                        lastResultsLength
                    }
                };
                setSearchResultsMap(newSearchResultsMap);
            },
            (err) => {
                const newSearchResultsMap = {
                    ...searchResultsMap,
                    [searchWord]: {
                        results: err,
                        totalCount: 0,
                        lastResultsLength: 0
                    }
                };
                setSearchResultsMap(newSearchResultsMap);
            },
            (searchWord === '') ? undefined : searchWord
        )

        if (usingSearchWord) withDelay(fetchNewsFunction);
        else fetchNewsFunction();

    }, [ usingSearchWord, searchInput, searchResultsMap, dataStatus, itemsPerPage ]);

    const getLink = (item: INewsLmdNewsItem) => {
        const { link, id, distributor } = item;
        if (distributor?.toLowerCase() === 'globenewswire' || distributor?.toLowerCase() === 'mfn' || distributor?.toLowerCase().includes('keldan'))
            return { link: `/Tilkynningar/${id}`, target: '_self' };
        return { link: link ?? '#', target: '_blank' };
    }
    
    const displayNewsItems = () => {
        const dataOrLastData = dataToDisplay ?? lastDisplayableData ?? null;
        if (dataOrLastData === null) {
            if (newsError === null) return <Loading />;
            else return <ErrorAlert error={newsError} />
        }
        return <Table
            columns={[{
                title: 'Dags.',
                renderCell: (item) => {
                    const { link, target } = getLink(item);
                    return <a className='clickableRow date' href={link} target={target} rel='noreferrer'>
                        {getTableDate(item.publish_date, 'DD/MM/YYYY', '.')}
                    </a>
                },
                textAlign: 'left'
            }, {
                title: 'Tími',
                renderCell: (item) => {
                    const { link, target } = getLink(item);
                    return <a className='clickableRow date--bold' href={link} target={target} rel='noreferrer'>
                        {getTimeFromDate(item.publish_date, 'HH:MM:SS')}
                    </a>
                },
                textAlign: 'left'
            }, {
                title: 'Tungumál',
                renderCell: (item) => {
                    const { link, target } = getLink(item);
                    return <a className='clickableRow date' href={link} target={target} rel='noreferrer'>
                        {item.lang?.toUpperCase()}
                    </a>
                },
                textAlign: 'center'
            }, {
                title: 'Titill',
                renderCell: (item) => {
                    const { link, target } = getLink(item);
                    return <td className='news-title'>
                        <a
                            className='clickableRow'
                            href={link}
                            target={target}
                            rel='noreferrer'
                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.title ?? '')}}
                        />
                    </td>
                },
                textAlign: 'left',
                simpleSortable: ({title}) => title,
                overrideTd: true
            }
            /* Not currently available in the API
            , {
                title: 'Viðhengi',
                renderCell: ({attachments, link}) => (
                    attachments && attachments.length > 0
                        ? <a className='clickableRow attachments-icon' href={link ?? '#'}>
                            <FontAwesomeIcon icon={faFile} />
                            <span className='attachments-nr'>
                                {attachments.length}
                            </span>
                        </a>
                        : null
                )
            }*/
            ]}
            data={dataOrLastData}
            tableSize='lg'
        />
    }

    const getMoreButtonText = () => {
        if (dataStatus === 'no data' || dataStatus === 'incomplete') return 'Hleður...';
        return 'Sýna fleiri fréttir';
    }

    return (
        <div className='main KCL_exchange-notices-page'>
            <div className='main__inner-fluid'>
                <div className='shell'>
                    <HeadSection
                        title={
                            <h1>
                                {newsOrgName}
                            </h1>
                        }
                        breadcrumbs={
                            <Link href="/Kauphallartilkynningar" icon='back' linkSize='14'>
                                Allar kauphallartilkynningar
                            </Link>
                        }
                        hasAds='main'
                    />
                    <div className='grid-items'>
                        <div className='grid-item grid-item--3of4 grid-item--table-full'>
                            <DisplayBox>
                                <div className='search-box'>
                                    <SmallSearch
                                        search={searchInput}
                                        setSearch={setSearchInput}
                                        placeHolder='Leitaðu eftir tilkynningum eða stikkorðum...'
                                    />
                                </div>
                                {displayNewsItems()}
                                {/* "Get more news" Button */}
                                <div className='load-more-button-wrapper'>
                                    {(dataStatus !== 'fully loaded') && (
                                        <Button
                                            size='lg'
                                            buttonType='secondary'
                                            onClick={() => {
                                                setItemsPerPage(itemsPerPage + ITEMS_TO_DISPLAY_PER_FETCH)
                                            }}
                                            disabled={dataStatus !== 'complete'}
                                        >
                                            {getMoreButtonText()}
                                        </Button>
                                    )}
                                </div>
                            </DisplayBox>
                        </div>
                        <div className='grid-item grid-item--1of4 grid-item--table-full'>
                            <NewsOrgInfo
                                symbol={sourceKey ?? ''}
                                setNewsOrgName={(name) => setNewsOrgName(name ?? '')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllExchangeNoticesPage;