// Package imports:
import React from 'react';
// Component imports:
import LmdTable from '../../ui-elements/Table/LmdTable';
import CalculatedColoredNumber from '../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import MetalLogo, { MetalLogoNames } from '../../ui-elements/MetalLogo/MetalLogo';
// Service imports:
import { formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCrypto } from '../../types/CurrencyTypes';

const METALS_TO_SHOW = ['XAU', 'XAG', 'XPT', 'XPD'];
type metal = 'XAU' | 'XPD' | 'XPT' | 'XAG';

export interface ICurrencyInputState {
    value: number | null,
    currency: string | null,
    averagerate: number | null
}

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Metals: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [ metals ] = useApiLmdData<IApiLmdCrypto[]>(
        `/v1/market_data/v1/keldan/metals/snapshot`,
        accessToken,
        CUSTOM_REFRESH_RATES['MALMAR']
    );

    const handleData = () => {
        const { data, error } = metals;
        if (data === null) {
            return metals;
        }
        const filteredData = data.filter(x => METALS_TO_SHOW.includes(x.base ?? ''))
        filteredData.sort((a, b) => {
            if (a.base === null && b.base === null) return 0;
            if (a.base === null) return -1;
            if (b.base === null) return 1;
            return METALS_TO_SHOW.indexOf(a.base) - METALS_TO_SHOW.indexOf(b.base);
        })
        return {
            error,
            data: filteredData
        }
    };
    const showDataBaseTimestamp = () => {
        if (metals.data) {
            const timeStamp = metals.data.find(x => x.base === 'XAU');
            if (timeStamp?.databasetimestamp) {
                //date is the same as now -> return time 
                if(new Date(timeStamp.databasetimestamp.toString()).getDate() === new Date().getDate()) return getTableDate(timeStamp.databasetimestamp, 'HH:MM','.')
                //else return date
                return getTableDate(timeStamp?.databasetimestamp, 'DD/MM/YY', '.');
            }
        } else {
            return '-'
        }
    }
    
    const nameOfMetal = (str: metal | null) => {
        switch(str){
            case 'XAU': return 'Gull';
            case 'XPD': return 'Palladín';
            case 'XPT': return 'Platína';
            case 'XAG': return 'Silfur';
            default: return str;
        }
    }
    return (
        <DisplayBox
            title="Eðalmálmar"
            className='KCL_metals'
            displayBoxSize="sm"
            asideComponent={<span className='extra-info'>Síðast uppfært: {showDataBaseTimestamp()}</span>}
        >
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Málmur',
                    renderCell: ({base}) => (
                        <div className='avatar-and-text'>
                            <MetalLogo name={base as MetalLogoNames} />
                            {nameOfMetal(base as metal)}
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({base}) => base
                }, {
                    title: 'Verð (USD)',
                    renderCell: ({averagerate}) => formatNumber(averagerate, '-', 2), 
                    simpleSortable: ({averagerate}) => averagerate
                }, {
                    title: 'Breyting',
                    renderCell: ({change_per}) => (
                        <CalculatedColoredNumber
                            currentValue={change_per ?? 0}
                            formatting='percent'
                            hasColor
                        />
                    ),
                    simpleSortable: ({change_per}) => change_per
                }]}
            />
        </DisplayBox>
    );
}

export default Metals;