// Package imports:
import React, { useContext, useEffect, useState } from 'react';
import AOS from "aos";
import 'aos/dist/aos.css';
//@ts-ignore
import Columns from 'react-columns';
// Component imports:
import Millibankavextir from '../Millibankavextir/Millibankavextir';
import Sereignarsjodir from '../Sereignarsjodir/Sereignarsjodir';
import Skuldabref from '../Skuldabref/Skuldabref';
import Hlutabref from '../Hlutabref/Hlutabref';
import Gjaldmidlar from '../Gjaldmidlar/Gjaldmidlar';
import VextirSedlabanka from '../VextirSedlabanka/VextirSedlabanka';
import Sjodir from '../Sjodir/Sjodir';
import Dagatal from '../Dagatal/Dagatal';
import Hagtolur from '../Hagtolur/Hagtolur';
import Frettir from '../Frettir/Frettir';
import AdRotator from '../Ad/AdRotator';
import Rafmyntir from '../Rafmyntir/Rafmyntir';
import FxMarketTable from '../FxMarketTable/FxMarketTableSmall';
import Visitolur from '../Visitolur/Visitolur';
import Metals from '../Metals/Metals';
// Service imports:
import { DEFAULT_REFRESH_RATE } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
// Context imports:
import { AccessTokenContext } from '../../contexts/AccessTokenContext';

const HomePage: React.FC = () => {
    
    const accessToken = useContext(AccessTokenContext);
    const [ defaultProps, setDefaultProps ] = useState<IDefaultProps>({
        accessToken,
        refreshRateMs: DEFAULT_REFRESH_RATE
    });

    useEffect(() => {
        setDefaultProps({
            ...defaultProps,
            accessToken
        });
    }, [accessToken]);

    useEffect(() => {
        AOS.init({
            easing: 'ease-in-out',
            duration: 500,
            once: true
        });
        window.history.scrollRestoration = 'manual'
    }, []);

    const queries = [
        {
            columns: 2,
            query: 'min-width: 1024px'
        }, {
            columns: 3,
            query: 'min-width: 1440px'
        }, {
            columns: 4,
            query: 'min-width: 2500px'
        }
    ];

    return (
        <div className='main KCL_home-page'>
            <div className="head-ad">
                {window.matchMedia('(max-width: 1023px)').matches 
                    ? <AdRotator location="Head300x250" />
                    : <AdRotator location="Head1360x180" />
                }
            </div>
            <div className="main__inner-fluid grid-items">
                <Columns queries={queries} className="col">
                    <div
                        className="grid-item"
                        data-aos="fade-in"
                    >
                        <Hlutabref id="FrontPage_Shares" {...defaultProps}/>
                        <AdRotator location="FrontPage310x400A" />
                    </div>

                    <div
                        className='grid-item news-frontpage-grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <Frettir id="FrontPage_News" {...defaultProps} />
                        <AdRotator location="FrontPage300x250Y" />
                    </div>

                    <div
                        className='grid-item sjodir-frontpage-grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <Sjodir id="FrontPage_Funds" {...defaultProps}/>
                        <div></div> {/* DO NOT REMOVE! Added to keep ads in right places when columns change */}
                        <AdRotator location="FrontPage310x400B" />
                    </div>

                    <div
                        className='grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <Gjaldmidlar id="FrontPage_Currencies" {...defaultProps}/>
                        <AdRotator location="FrontPage310x400C" />
                    </div>

                    <div
                        className='grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <Dagatal id="FrontPage_Calendar" {...defaultProps}/>
                    </div>

                    <div
                        className='grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <Sereignarsjodir id="FrontPage_SpecialFunds" {...defaultProps}/>
                        <AdRotator location="FrontPage310x400D" />
                    </div>

                    <div
                        className='grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <FxMarketTable id="FrontPage_Gjaldeyrismarkadur" {...defaultProps}/>
                        <Rafmyntir id="FrontPage_Rafmyntir" {...defaultProps}/>
                        <Visitolur id="FrontPage_Visitolur" {...defaultProps}/>
                    </div>

                    <div
                        className='grid-item'
                        data-aos="fade-in"
                        data-aos-delay="450"
                    >
                        <Skuldabref id="FrontPage_Bonds" {...defaultProps}/>
                        <VextirSedlabanka id="FrontPage_CentralBankRates" {...defaultProps}/>
                        <Millibankavextir id="FrontPage_BankRates" {...defaultProps}/>
                    </div>

                    <div
                        className='grid-item'
                        data-aos="fade-in"
                        data-aos-delay="50"
                    >
                        <Hagtolur id="FrontPage_EconomicIndicators" {...defaultProps}/>
                        <AdRotator location="FrontPage310x400E" />
                        <Metals id="FrontPage_Metals" {...defaultProps}/>
                    </div>
                </Columns>
            </div>
        </div>
    );
}

export default HomePage;